import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export default function TawkMessengerIcon() {
    const tawkMessengerRef = useRef();

    return (
        <div className="tawk-messenger-icon">
            <TawkMessengerReact
                propertyId="61fbbc96b9e4e21181bd45bf"
                widgetId="1fqvlhbj3"
                useRef={tawkMessengerRef}
                customStyle={{
                    visibility: {
                        desktop: {
                            xOffset: '70',
                            yOffset: '45',
                        },
                    }
                }}
            />
        </div>
    );
}